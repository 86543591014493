body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-toggle::after {
  content: none;
}
td {
  padding: 0;
  margin: 0;
}
.btn {
  padding: 0;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #003863;
  border-radius: 20px;
  border: 2px solid #ffffff;
}

p {
  margin: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .headerTableResponsiveColumn {
  color: #083c64;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  user-select: none;
}  */


.headerTableResponsiveColumn:hover {
  -webkit-filter: brightness(1.5);
          filter: brightness(1.5);
}
.headerTable {
  width: 100%;
  height: 5vw;
  border-bottom: 0.01vw solid #083c64;
}
.headerTable td {
  width: 4vw;
  color: #083c64;
  font-weight: 500;
  font-size: 0.8vw;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.headerTable td:hover {
  -webkit-filter: brightness(1.5);
          filter: brightness(1.5);
}

.LoginSignupButton:focus {
  background-color: #083c64;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.LoginSignupButton:active {
  background-color: #083c64;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.LoginSignupButton:hover {
  background-color: #083c64;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.LoginSignupFormControl {
  background-color: rgb(226, 226, 226);
  height: 3vw;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 3vw;
  color: rgb(0, 0, 0);
  opacity: 1; /* Firefox */
  padding-left: 2vw;
  font-weight: 500;
  font-size: 1.45vw;
}

.form-control::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(93, 93, 93);
  opacity: 1; /* Firefox */

  font-weight: 500;
  font-size: 120%;
}

.form-control:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(93, 93, 93);
  opacity: 1; /* Firefox */

  font-weight: 500;
  font-size: 120%;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(93, 93, 93);
  opacity: 1; /* Firefox */

  font-weight: 500;
  font-size: 120%;
}
.form-control:focus {
  background-color: rgb(226, 226, 226);
  border: none;
  outline: none;
  box-shadow: none;
}

.hyperlinkText {
  color: #083c64;
  cursor: pointer;
}

.myAccountBar {
  background-color: rgb(231, 231, 231);
  height: 4vw;
  display: flex;
  align-items: center;
  padding-inline: 20vw;
  border-bottom: 0.1vw solid rgb(119, 119, 119);
}

.chosenOptionMyAccountSelected {
  border: 0.103vw solid rgb(102, 102, 102);
  background-color: rgb(200, 200, 200);
  border-radius: 3vw;
  color: #083c64;
  font-size: 1.3vw;
  font-weight: 375;
  box-sizing: border-box;
  padding: 0.1vw 1vw 0.3vw 1vw;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.chosenOptionMyAccountUnselected {
  color: #083c64;
  cursor: pointer;
  font-size: 100%;
  font-weight: 375;
  box-sizing: border-box;
  padding: 0.1vw 1.103vw 0.3vw 1.103vw;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chosenOptionMyAccountUnselected:hover {
  -webkit-filter: brightness(1.5);
          filter: brightness(1.5);
}

.courseCardWrapper {
  width: 85%;
  cursor: pointer;
  border-left: 0.1vw solid #c7c7c7;
  border-right: 0.1vw solid #c7c7c7;
  border-bottom: 0.1vw solid #c7c7c7;
  border-radius: 4%;
}

.courseCardWrapper:hover {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}

.courseCardField {
  /* font-size: 0.8vw; */
  color: #7e7e7e;
  padding-left: 7%;
  text-align: justify;
  /* width: 14.5vw; */
  width: 75%;
}
.courseCardFieldSelected {
  font-size: 0.8vw;
  color: white;
  padding-left: 1.3vw;
  text-align: justify;
  width: 14.5vw;
}
.courseCardViews {
  /* width: 7.5vw; */
  /* margin-top: -0.7vw; */
  /* margin-left: -3.5vw; */
  /* font-size: 0.8vw; */
  color: #7e7e7e;
  text-align: end;
  padding-right: 6%;
}

.courseCardTime {
  /* font-size: 0.8vw; */
  font-weight: 600;
  color: rgb(52, 179, 111);
  /* width: 3.5vw; */
  margin-left: 20%;
  width: 60%;
  border: 0.103vw solid #ebebeb;
  background-color: #ebebeb;
  border-radius: 3vw;
  box-sizing: border-box;
  padding: 0.1vw 0.1vw 0.3vw 0.1vw;
}
.courseCardTime h6{
margin-bottom: 0;
}
.courseCardName {
  /* font-size: 1vw; */
  font-weight: 600;
  color: #000000;
  /* padding-left: 1.3vw; */
  padding-left: 7%;
  /* text-align: justify; */
  width: 75%;
}

.layer {
  background-color: rgba(248, 247, 216, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.courseFilter {
  background-color: #7f9bb0;
  max-height: 36px;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 40px;
  color: white !important;
  opacity: 1; /* Firefox */
  padding-left: 3.5%;

  font-weight: 400;
  /* font-size: 10px; */
  /* font-display: ; */
}

.courseFilter::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  /* font-variant: h6; */
  font-weight: 400;
  font-size: 100%;
}

.courseFilter:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  /* font-variant: h6; */
  font-weight: 400;
  font-size: 100%;
}

.courseFilter::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  /* font-variant: h6; */
  font-weight: 400;
  font-size: 100%;
}
.courseFilter:focus {
  background-color: #7f9bb0;
  border: none;
  outline: none;
  box-shadow: none;
}
.myAccountBarResponsive {
  background-color: rgb(231, 231, 231);
  height: 7vh;
  display: flex;
  align-items: center;
  padding-inline: 20%;
  border-bottom: 0.1vw solid rgb(119, 119, 119);
}

.courseBarResponsive {
  background-color: rgb(231, 231, 231);
  height: 7vh;
  display: flex;
  align-items: center;
  padding-inline: 23%;
  border-bottom: 0.1vw solid rgb(119, 119, 119);
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 1160px) {
  .headerTableResponsiveColumn {
    color: #083c64;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ResponsiveCoursesContainer {
    width: 80%;
    margin-left: 10%;
  }
  .headerTableResponsive {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #083c64;
  }
  .headerLogoResponsiveWidth {
    width: 120px;
  }
  .headerUserResponsiveWidth {
    width: 40px;
  }
  .LoginSignupButton {
    margin-top: 2%;
    width: 15%;
    height: 60px;
    background-color: #083c64;
    /* font-size: 1.4vw; */
    font-weight: 500;
    border-radius: 60px;
    border: none;
    outline: none;
  }
  .LoginSignupFormControlResponsive {
    background-color: rgb(226, 226, 226);
    height: 6vh;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 30px;
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox */
    padding-left: 3%;
    font-weight: 500;
  }
  .responsiveLoginFormWidth {
    width: 65%;
  }
  .responsiveSignUpNameInput {
    width: 90%;
  }
  .responsiveSignUpNameInputLastName {
    margin-left: 10%;
  }
  .CourseSubmitAnswersButton {
    margin-top: 3%;
    width: 25%;
    height: 50px;
    background-color: #475677;
    /* font-size: 1.2vw; */
    color: white;
    font-weight: 500;
    border-radius: 30px;
    border: none;
    outline: none;
  }
  .shortQuestion {
    /* margin-right: 20%; */
    width: 55%;
    border: 2px solid #475677;
    /* font-size: 1vw; */
    padding-left: 1%;
  }

  .chosenOptionCourseUnselectedResponsive {
    color: #083c64;
    cursor: pointer;
    font-size: calc(12px + 1.5vmin);
    font-weight: 375;
    box-sizing: border-box;
    padding: 0.1vw 1.103vw 0.3vw 1.103vw;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .CourseHeader {
    background-color: #083c64;
    color: white;
    padding-top: 6.5%;
    padding-left: 20%;
    padding-bottom: 3%;
  }
  .CoursesHeaderResponsive {
    background-color: #083c64;
    height: 290px;
  }
  .CategoriesButton {
    font-size: 1rem;
  }
  .CategoriesButtonArrow {
    font-size: 0.8rem;
  }
  .LoginSignupHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #083c64;
    vertical-align: middle;
    /* font-size: 3.5vw; */
    color: white;
    font-weight: bold;
    text-align: center;
    height: 240px;
    /* width: 100%; */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .courseCategoriesButton {
    margin-left: -11%;
    width: 11%;
    height: 100%;
    background-color: #3f6989;
    font-weight: 400;
    /* font-size: 0.8vw; */
    border-radius: 40px;
    border: none;
    outline: white solid 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .courseCategoriesButton2 {
    margin-left: -11%;
    width: 11%;
    height: 100%;
    background-color: #00689f;
    font-weight: 400;
    /* font-size: 0.8vw; */
    border-radius: 40px;
    border: none;
    outline: #7395ad solid 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .courseFilterResponsive {
    display: flex;
    justify-content: center;
  }
  .chosenOptionMyAccountUnselectedResponsive {
    color: #083c64;
    cursor: pointer;
    font-size: 24px;
    font-weight: 375;
    box-sizing: border-box;
    padding: 0.1vw 1.103vw 0.3vw 1.103vw;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .chosenOptionMyAccountSelectedResponsive {
    border: 0.103vw solid rgb(102, 102, 102);
    background-color: rgb(200, 200, 200);
    border-radius: 30px;
    color: #083c64;
    font-size: 24px;
    font-weight: 375;
    box-sizing: border-box;
    padding: 0.1vw 1vw 0.3vw 1vw;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .trainerResponsive {
    margin-left: -25%;
  }
  .paddingInlineResponsive {
    padding-inline: 24%;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 0px) and (max-width: 1159.98px) {
  .headerTableResponsiveColumn {
    color: #083c64;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ResponsiveCoursesContainer {
    margin: 0;
    width: 100%;
  }
  .headerTableResponsive {
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin: 0;
    border-bottom: 1px solid #083c64;
  }
  .headerLogoResponsiveWidth {
    width: 80px;
  }
  .headerUserResponsiveWidth {
    width: 25px;
  }
  .LoginSignupButton {
    margin-top: 5%;
    width: 50%;
    height: 50px;
    background-color: #083c64;
    /* font-size: 1.4vw; */
    font-weight: 500;
    border-radius: 60px;
    border: none;
    outline: none;
  }
  .LoginSignupFormControlResponsive {
    background-color: rgb(226, 226, 226);
    height: 6vh;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 30px;
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox */
    padding-left: 5%;
    font-weight: 500;
    margin-bottom: 5%;
  }
  .responsiveLoginFormWidth {
    width: 90%;
  }
  .responsiveSignUpNameInput {
    width: 100%;
  }
  .CourseSubmitAnswersButton {
    margin-top: 10%;
    width: 60%;
    height: 50px;
    background-color: #475677;
    /* font-size: 1.2vw; */
    color: white;
    font-weight: 500;
    border-radius: 30px;
    border: none;
    outline: none;
  }
  .shortQuestion {
    /* margin-right: 20%; */
    width: 90%;
    border: 2px solid #475677;
    /* font-size: 1vw; */
    padding-left: 1%;
  }

  .paddingInlineResponsive {
    padding-inline: 5%;
  }
  .CourseHeader {
    background-color: #083c64;
    color: white;
    padding-top: 6.5%;
    padding-bottom: 3%;
    /* padding-left: 20%; */
  }
  .CourseHeader .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .CourseHeader .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .CoursesHeaderResponsive {
    background-color: #083c64;
    height: 150px;
  }
  .CategoriesButton {
    font-size: 1rem;
  }
  .CategoriesButtonArrow {
    font-size: 0.8rem;
  }
  .LoginSignupHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #083c64;
    vertical-align: middle;
    /* font-size: 3.5vw; */
    color: white;
    font-weight: bold;
    text-align: center;
    height: 130px;
    /* width: 100%; */
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .courseCategoriesButton {
    /* margin-left: -30%; */
    margin-top: 5%;
    margin-bottom: 4%;
    width: 60%;
    height: 100%;
    background-color: #3f6989;
    font-weight: 400;
    /* font-size: 0.8vw; */
    border-radius: 40px;
    border: none;
    outline: white solid 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .courseCategoriesButton2 {
    margin-top: 5%;
    width: 60%;
    height: 100%;
    background-color: #00689f;
    font-weight: 400;
    /* font-size: 0.8vw; */
    border-radius: 40px;
    border: none;
    outline: #7395ad solid 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .courseFilterResponsive {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .myAccountBarResponsive {
    background-color: rgb(231, 231, 231);
    height: 7vh;
    display: flex;
    align-items: center;
    padding-inline: 5%;
    justify-content: center;
    border-bottom: 0.1vw solid rgb(119, 119, 119);
  }
  .courseBarResponsive {
    background-color: rgb(231, 231, 231);
    height: 7vh;
    display: flex;
    align-items: center;
    /* padding-inline: 0%; */
    justify-content: center;
    border-bottom: 0.1vw solid rgb(119, 119, 119);
  }
  .chosenOptionCourseUnselectedResponsive {
    color: #083c64;
    cursor: pointer;
    font-size: calc(12px + 1.5vmin);
    font-weight: 375;
    box-sizing: border-box;
    padding: 0.1vw 1.103vw 0.3vw 1.103vw;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .chosenOptionMyAccountUnselectedResponsive {
    color: #083c64;
    cursor: pointer;
    font-size: calc(8px + 1vmin);
    font-weight: 375;
    box-sizing: border-box;
    padding: 0.1vw 1.103vw 0.3vw 1.103vw;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .chosenOptionMyAccountSelectedResponsive {
    border: 0.103vw solid rgb(102, 102, 102);
    background-color: rgb(200, 200, 200);
    border-radius: 30px;
    color: #083c64;
    font-size: calc(8px + 1vmin);
    font-weight: 375;
    box-sizing: border-box;
    padding: 0.1vw 1vw 0.3vw 1vw;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
}

.courseCategoriesButton:focus {
  background-color: #3f6989;
  border: none;
  outline: white solid 2px !important;
  box-shadow: none !important;
}
.courseCategoriesButton:active {
  background-color: #3f6989;
  border: none;
  outline: white solid 2px;
  box-shadow: none !important;
}
.courseCategoriesButton:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #3f6989;
  border: none;
  outline: white solid 2px;
  box-shadow: none !important;
}

.courseFilter2 {
  /* font-size: 80%; */
  background-color: #00588b;
  max-height: 36px;
  border: none;
  outline: #7395ad solid 0.17vw;
  box-shadow: none;
  /* border-radius: 4vw; */
  color: white !important;
  /* opacity: 1; Firefox
  padding-left: 2vw;

  font-weight: 400;
  font-size: 0.8vw; */
}

.courseFilter2::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  /* font-variant: h6; */
  font-weight: 400;
  font-size: 100%;
}

.courseFilter2:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  /* font-variant: h6; */
  font-weight: 400;
  font-size: 100%;
}

.courseFilter2::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  /* font-variant: h6; */
  font-weight: 400;
  font-size: 100%;
}
.courseFilter2:focus {
  background-color: #00588b;
  border: none;
  outline: #7395ad solid 0.17vw;
  box-shadow: none;
}

.courseCategoriesButton2:focus {
  background-color: #00689f;
  border: none;
  outline: #7395ad solid 0.17vw !important;
  box-shadow: none !important;
}
.courseCategoriesButton2:active {
  background-color: #00689f;
  border: none;
  outline: #7395ad solid 0.17vw;
  box-shadow: none !important;
}
.courseCategoriesButton2:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #00689f;
  border: none;
  outline: #7395ad solid 0.17vw;
  box-shadow: none !important;
}
.courseFilterDropdown {
  position: absolute;
  top: 68.7%;
  right: 20.1%;
  width: 10vw;
  overflow: hidden;
  outline: #7395ad solid 0.17vw;
  z-index: 1;
}
.courseFilterDropdown2 {
  position: absolute;
  top: 12.5vw;
  right: 20vw;
  width: 10vw;
  overflow: hidden;
  outline: #7395ad solid 0.17vw;
  z-index: 1;
}

.courseFilterDropdownItem {
  background-color: #3f6989;
  height: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 500ms;
  color: white;
  font-size: 0.8vw;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.courseFilterDropdownItem:hover {
  background-color: #7f9bb0;
  color: white;
}

.homeBanner {
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.52), #033b64),
    url(/static/media/banner.ad9bc811.jpg);
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  width: 100%;
  height: 70vh;
  background-size: cover;
  color: white;
  /* padding-left: 18vw; */
  padding-top: 5%;
}

.homeP1 {
  /* font-size: 3.2vw; */
  font-weight: 700;
}
.homeP2 {
  /* margin-top: -1.5vw; */
  /* font-size: 2.3vw; */
  font-weight: 500;
}
.homeP3 {
  margin-top: 4%;
  /* font-size: 0.9vw; */
  font-weight: 400;
  width: 70%;
}
.homeCategoryfont {
  color: #003863;
  /* font-size: 1.3vw; */
  font-weight: 650;
  text-align: center;
  /* margin-top: -40px; */
}

.homeCategoryBoundry {
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 70%;
  position: relative;
  height: 250px;
  border: 2px solid rgb(206, 206, 206);
  border-radius: 20px;
}

.homeCategoryBoundry:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.instructorAvatar {
  vertical-align: middle;
  margin-left: 40%;
  width: 20%;
  border-radius: 50%;
  cursor: pointer;
}

.enrolledButton {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  margin-top: 6%;
  width: 50%;
  height: 50px;
  background-color: #ff9200;
  color: white;
  /* font-weight: 650; */
  /* font-size: 1.05vw; */
  border-radius: 40px;
  border: none;
  outline: white solid 2px;
  display: flex;
  justify-content: center;
}

.notEnrolledButton {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 6%;
  width: 50%;
  height: 50px;
  background-color: transparent;
  color: white;
  /* font-weight: 650; */
  /* font-size: 1.05vw; */
  border-radius: 40px;
  border: none;
  outline: white solid 2px;
  display: flex;
  justify-content: center;
}
.notEnrolledButton:hover {
  background-color: #145485;
}

.courseAboutTitle {
  /* font-size: 4vw; */
  font-weight: 700;
  color: #475677;
}

.courseAboutDescription {
  /* font-size: 1vw; */
  color: #7f8599;
  font-weight: 500;
}

.courseInstructorName {
  /* font-size: 2.3vw; */
  font-weight: 600;
}

.syllabusTableWrapper {
  margin-top: 3%;

  overflow: hidden;
  border-spacing: 0;
  padding: 0;
  width: 97%;
  border: 1px solid rgb(102, 102, 102);
  border-radius: 10px;
  background-color: #ecedf1;
}

.syllabusTable {
  border-spacing: 0;
  width: 100%;
}

.syllabusTable tr {
  height: 70px;
  border-bottom: 3px solid #818ca2;
}
.syllabusTable .row {
  min-height: 70px !important;
  --bs-gutter-x: 0rem;
}
.syllabusTable tr:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.syllabusTable tr:last-child {
  border-bottom: 0;
}

.syllabusTableCP {
  border-spacing: 0;
  width: 100%;
}

.syllabusTableCP tr {
  height: 70px;
  border-bottom: 3px solid #818ca2;
}

.syllabusTableCP tr:last-child {
  border-bottom: 0;
}

.syllabusTableCPOption:hover {
  cursor: pointer;
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
}

.syllabusChapterName {
  /* display: flex; */
  /* font-size: 1.1vw; */
  /* font-weight: 300; */
  color: rgb(73, 82, 107);
  /* padding-top: 3.2%;
  padding-left: 13%; */
}
.syllabusChapterNameSelected {
  /* display: flex; */
  /* font-size: 1.1vw; */
  /* font-weight: 300; */
  color: white;
  /* padding-top: 3.2%;
  padding-left: 13%; */
}
.syllabusChapterNameCP {
  height: 3.5vw;
  display: flex;
  font-size: 1.3vw;
  font-weight: 600;
  color: rgb(41, 46, 59);
  align-items: center;
  padding-left: 1vw;
}

.courseTimeSyllabus {
  text-align: end;
  /* width: 9vw; */
  /* font-size: 1vw; */
  color: rgb(73, 82, 107);
  font-weight: 600;
}
.courseTimeSyllabusSelected {
  text-align: end;
  /* width: 9vw; */
  /* font-size: 1vw; */
  color: white;
  font-weight: 600;
}
.selectedSection {
  background-color: #475677;
}
.unavailableSection {
  cursor: default !important;
  background-color: #cecece;

  -webkit-filter: brightness(1);

          filter: brightness(1);
}
.unavailableSection:hover {
  -webkit-filter: brightness(1) !important;
          filter: brightness(1) !important;
}
.selectedSection p {
  color: white !important;
}
.selectedSection td {
  color: white !important;
}

.questionText {
  /* font-size: 1.2vw; */
  color: #475677;
  font-weight: 650;
}

.form-check-input:checked {
  background-color: #475677;
  border-color: #475677;
}
.form-check-input:focus {
  box-shadow: none;
}

.shortQuestion:focus {
  background-color: white;
  border: 2px solid #475677;
}

.SubmitAnswersButton {
  margin-top: 2vw;
  width: 20%;
  height: 3vw;
  background-color: #475677;
  font-size: 1.2vw;
  color: white;
  font-weight: 500;
  border-radius: 3vw;
  border: none;
  outline: none;
}
.SubmitAnswersButton:focus {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #475677;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.SubmitAnswersButton:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #475677;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.CourseSubmitAnswersButton:focus {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #475677;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.CourseSubmitAnswersButton:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #475677;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.page-controls {
  position: absolute;
  background: white;
  opacity: 0;
  -webkit-transform: translateY(42vw);
          transform: translateY(42vw);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 1.5vw 2vw 0 rgb(16 36 94 / 20%);
  border-radius: 0.25vw;
  font-size: 1vw;
}
.page-controls button {
  width: 2.5vw;
  height: 2.5vw;
  background: white;
  border: 0;
  outline: none;
  font: inherit;
  font-size: 1vw;
  border-radius: 0.25vw;
}

.page-controls button:enabled:hover {
  background-color: #e6e6e6;
}
.page-controls button:first-child {
  margin-right: 0.3vw;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-controls button:last-child {
  margin-left: 0.3vw;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.PDFWrapper:hover .page-controls {
  opacity: 1;
}
.uploadButtons button {
  background-color: white;
  color: #3f6989;
  border: 0.3vw solid #475677;
  font-weight: 600;
  width: 6vw;
  height: 2vw;
  font-size: 0.85vw;
  margin-left: 0.3vw;
  margin-right: 0.3vw;
}

.uploadButtons button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.Popup-modalRadius > div:first-child {
  padding-left: 1%;
  border-radius: 0vw;
  resize: none;
  width: 100%;
  max-height: 80vh;
  overflow-y: none;
  overflow-x: hidden;
}

.controlPanelButton {
  width: 15vw;
  height: 2.2vw;
  background-color: #3f6989;
  font-weight: 400;
  font-size: 0.8vw;
  border-radius: 4vw;
  border: none;
  outline: white solid 0.15vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controlPanelButton:focus {
  background-color: #3f6989;
  border: none;
  outline: white solid 0.15vw !important;
  box-shadow: none !important;
}
.controlPanelButton:active {
  background-color: #3f6989;
  border: none;
  outline: white solid 0.15vw;
  box-shadow: none !important;
}
.controlPanelButton:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  background-color: #3f6989;
  border: none;
  outline: white solid 0.15vw;
  box-shadow: none !important;
}
.stickyButton{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 16px;
}
.btn-close:focus{
 box-shadow: none !important;
}

.controlPanelDropdown {
  z-index: 1;
  position: absolute;
  top: 24.45vw;
  width: 15vw;
  overflow: hidden;
  outline: #7395ad solid 0.17vw;
}

.successResponse {
  display: flex;
  justify-content: center;
  color: green;
  font-size: 1vw;
}

.dropdown-item {
  font-size: 1vw;
}

.dropdown-item:hover {
  color: white;
  background-color: #3f6989;
}

.courseCategoriesButton .dropdown-toggle {
  max-height: 36px;
  outline: 0;
  font-size: 1vw;
  box-shadow: none;
  border: none;
  background-color: #3f6989 !important;
  padding: 5px 10px;
}
.courseCategoriesButton2 .dropdown-toggle {
  max-height: 36px;
  outline: 0;
  font-size: 1vw;
  box-shadow: none;
  border: none;
  /* height: 120%; */
  background-color: #00689f !important;
  padding: 5px 10px;
}
/* .dropdown-toggle:hover {
  filter: brightness(1.2);
} */

.dropdown-toggle:focus,
dropdown-toggle:active,
dropdown-toggle:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.row > * {
  padding-right: 0;
  padding-left: 0;
}
.analyticsCard {
  margin: 1vw;
  width: 15vw;
  height: 7vw;
  background-color: white;
  /* border: 0.02vw solid rgb(184, 184, 184); */
  padding-left: 1vw;
  padding-top: 0.7vw;
  box-shadow: 0.1vw 0.1vw 0.2vw 0.1vw #888888;
}
.analyticsCard p {
  font-size: 1.2vw;
  font-weight: 500;
}
.analyticPageContainer {
  margin-left: 1vw;
}
.dropdown-menu.show {
  display: block;
  max-height: 20vw !important;
  overflow: auto !important;
}

.analyticsSideBar {
  /* margin-left: 0.2vw; */
  min-height: 100vh;
  padding-top: 2vw;
  width: 15vw;
  background-color: #ffffff;
  /* border: 0.15vw solid #003863; */
  box-shadow: 0vw 0.15vw 0.5vw 0vw #013d6b;
}
.analyticsSideBar ul {
  width: 13.5vw;
  padding: 0;
  padding-left: 0.8vw;
}

.analyticsSideBar ul li {
  list-style-type: none;
  font-size: 1.3vw;
  color: #003863;
  font-weight: 700;
  margin-bottom: 1vw;
}
.analyticsSideBar ul li div {
  padding-left: 2vw;
  height: 2.3vw;
  border: 0.15vw transparent #003863;
  border-radius: 1.4vw;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.analyticsSideBar ul li div:hover {
  /* filter: brightness(1); */
  background-color: #e6e6e6;
  /* color: white; */
  cursor: pointer;
}

.analyticUserPageContainer {
  /* margin-left: 1vw; */
  font-size: 1vw;
}

.analyticUserPageContainer .col {
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.15vw solid #cfcfcf;
  overflow: hidden;
  /* border-bottom: 0; */
  /* border-right: 0; */
}

.analyticUserPageContainer .col:first-child {
  flex: 0 0 auto;
  width: 50%;
}
.analyticsUserPageFirstRow {
  background-color: #003863;
  color: white;
}

.analyticUserPageContainer .row:first-child .col {
  /* border-bottom: 0.12vw solid #003863; */
  text-align: center;
  justify-content: center;
}
.analyticUserPageContainerCollapsed .row .col {
  text-align: start !important;
  justify-content: start !important;
}

.analyticUserPageContainerHover:hover .col {
  border-color: #003863;
  /* filter: brightness(2); */
  cursor: pointer;
}

.myScoreContainer {
  /* margin-left: 1vw; */
  font-size: max(10px , 1.1vw);
}

.myScoreContainer .col {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.5px solid #cfcfcf;
  overflow: hidden;
  /* border-bottom: 0; */
  /* border-right: 0; */
}

.myScoreContainerFirstRow {
  background-color: #003863;
  color: white;
}

.myScoreContainerCollapsed .row .col {
  text-align: start !important;
  justify-content: start !important;
}

.myScoreContainerHover:hover .col {
  border-color: #003863;
  /* filter: brightness(2); */
  cursor: pointer;
}
a {
  color: #003863;
  text-decoration: none;
}

.analyticsSideBarSelected {
  border-radius: 3vw;
  box-sizing: border-box;
  /* padding: 0.1vw 1vw 0.3vw 1vw; */
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #083c64;
  background-color: rgb(231, 231, 231);
  border: 0.103vw solid rgb(102, 102, 102);
}

.printButtons {
  margin-bottom: 0.3vw;
  display: flex;
  justify-content: center;
}
.printButtons button {
  background-color: white;
  color: #003863;
  border: 0.3vw solid #003863;
  /* background-color: #003863;
  color: white;
  border: 0.3vw solid white; */
  font-weight: 600;
  width: 7.5vw;
  height: 2vw;
  font-size: 0.85vw;
  margin-left: 0.3vw;
  margin-right: 0.3vw;
}
.printButtons button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}
.centerContent {
  display: flex;
  justify-content: center;
}
.analyticsUserHeader {
  display: flex;
  justify-content: center;
}
.analyticsUserHeader div {
  font-size: 1vw;
  color: #003863;
  font-weight: 700;
  margin: 0.5vw;
  padding: 0.1vw 0.5vw;
  margin-top: 0;
  border: 0.15vw transparent #003863;
  border-radius: 1.4vw;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.analyticsUserHeader div:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
.noOverflow {
  overflow-x: hidden;
}
.dropdown-menu.show {
  max-height: 25vh !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  background-color: rgb(8, 60, 100) !important;
  padding: 0.1rem 0.5rem;
}
.countryButton{
  justify-content: center;
  align-content: center;
  display: flex;
}
.countryButton button{
  margin-top: 3%;
  width: 50%;
  height: 4vh;
  align-self: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* padding-top: 40%; */
  /* padding-bottom: -25%; */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.analyticsDropdown{
  width: 30%;
  margin-top: 16px;
  justify-content: center;
  align-content: center;
  display: flex;
}
.analyticsDropdown .dropdown{
  justify-content: center;
  align-content: center;
  display: flex;
  width: 50%;
}
.analyticsDropdown button{
  font-size: 1vw;
  width: 90%;
  align-self: center;
}

.analyticsRemoveFilters{
  margin-top: 16px;
  width: 5%;
}
.analyticsRemoveFilters button{
  width: 100%;
  height: 100%;
  padding: 0;
}
.analyticsRemoveFilters svg{
  width: 65%;
  height: 65%;
  color: #003863;
}

.responsiveLoginFormWidth label{
  background-color: gainsboro;
  padding: 5px;
  border: 2px solid #083c64;
  width: 100%;
}
.responsiveLoginFormWidth input#privacyPolicy{
  /* background-color: #083c64; */
  margin-top: 7.5px;
  border-color:#083c64;
}

.privacyPolicyPopupText {
  background-color: gainsboro;
  padding: 10px;
  margin: 30px;
  border: 4px solid #083c64;
}
.languageFlagsSelected{
  border: 8px solid #083c64;
  -webkit-filter: brightness(1) !important;
          filter: brightness(1) !important;
  
}
